/**** History ****/
section#history {
    /* position */
    position: relative;

    /* sizing */
    width: 100%;
}

section#history .container {
    /* sizing */
    height: 100%;
    width: 100%;
    padding: var(--header-style-padding);

    /* flex */
    display: flex;
    flex-direction: column;
    gap: var(--default-padding);
}

section#history .container h2 {
    /* text */
    text-align: center;
    font-size: 26px;
    font-weight: 800;
    line-height: 1;
}

section#history .container ul {
    /* pacing */
    padding: 0;
    margin: 0;

    /* grid */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 1fr));
    gap: var(--default-padding);
}

section#history .container ul li {
    /* position */
    position: relative;

    /* sizing */
    width: 100%;
    aspect-ratio: 1/1;

    /* flex */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    gap: calc(var(--default-padding) / 2);

    /* styling */
    border-radius: 10px;
    overflow: hidden;
}

section#history .container ul li img {
    /* position */
    position: absolute;
    inset: 0;
    z-index: -1;

    /* sizing */
    width: 100%;
    height: 100%;

    /* image */
    object-fit: cover;
}

section#history .container ul li .info {
    /* sizing */
    width: 100%;
    padding: var(--default-padding);
    padding-top: calc(var(--default-padding) * 2);

    /* flex */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    gap: calc(var(--default-padding) / 2);

    /* gradient */
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.6) 65%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.6) 65%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.6) 65%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
}

section#history .container ul li .info span.title {
    /* sizing */
    --size: 18px;
    height: var(--size);
    max-width: 100%;

    /* text */
    font-size: var(--size);
    font-weight: 700;
    line-height: 1;

    /* overflow */
    overflow: hidden;
    text-overflow: ellipsis;

    /* styling */
    color: var(--dnu-light-color-100);
}

section#history .container ul li .info span.subTitle {
    /* text */
    font-size: 14px;
    font-weight: 500;
    line-height: 1;

    /* styling */
    color: var(--dnu-light-color-200);
}