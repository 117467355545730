*,
*::after,
*::before {
  /* sizing */
  box-sizing: border-box;

  /* font */
  font-family: 'Montserrat', sans-serif;
}

body {
  /* sizing */
  width: 100%;
  min-height: 100vh;
  margin: 0;

  /* background */
  background-color: var(--background-color-100);
  color: var(--foreground-color-100);

  /* transition */
  transition: var(--transition-background_color-600), var(--transition-color-600);
}

html {
  scroll-behavior: smooth;
}

#root {
  /* sizing */
  min-height: 100vh;
  width: 100%;
}

#root>main {
  min-height: 85vh;
}

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}

.material-symbols-outlined.active {
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}